import styled from "styled-components";
import { getPhotoSrc } from "../../utils/getPhotoSrc";
import { SaveTheDateText } from "./SaveTheDateText";
import { KunalAndForumText } from "./KunalAndForumText";
import { Section } from "../Section";

const HeroBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #34111a;
`;

const FiftyFiftySection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  gap: 20px;
  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

const HeroPhoto = styled.img`
  width: 100%;
  border-radius: 100% 100% 0 0; /* Creates the semicircle effect */
  object-fit: cover; /* Ensures the image covers the container */
`;
const HeroPhotoContainer = styled.div`
  width: 45%;
  align-self: center;
  @media (max-width: 1000px) {
    width: 80%;
    align-self: center;
  }
`;

export const HeroBanner = () => {
  return (
    <Section>
      <HeroBannerContainer>
        <SaveTheDateText />
        <FiftyFiftySection>
          <KunalAndForumText />
          <HeroPhotoContainer>
            <HeroPhoto src={getPhotoSrc("photo_2_square")} />
          </HeroPhotoContainer>
        </FiftyFiftySection>
      </HeroBannerContainer>
    </Section>
  );
};
