import styled from "styled-components";

const Card = styled.div`
  width: 500px;
  padding: 40px;
  border-radius: 10px;
  background-color: #f0ece8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 900px) {
    width: 80%;
  }
`;

const ProfileImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const Content = styled.div`
  text-align: center;
`;

const Name = styled.h2`
  font-family: "TheSeasons";
  margin: 10px 0;
  font-size: 1.5rem;
`;

const Description = styled.p`
  font-family: "Lato", sans-serif;
  font-size: 1rem;
`;

export const ProfileCard = ({ imageSrc, name, description }) => {
  return (
    <Card>
      <ProfileImage src={imageSrc} alt={`${name}'s profile`} />
      <Content>
        <Name>{name}</Name>
        <Description>{description}</Description>
      </Content>
    </Card>
  );
};
