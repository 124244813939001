import styled from "styled-components";
import { Text } from "./SaveTheDateText";
import { atcb_action } from "add-to-calendar-button";
import { Calendar } from "lucide-react";

const KunalAndForumTextWrapper = styled.div`
  padding: 20px;
`;

const KunalAndForumTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: fit-content;
  margin: 0 auto;
  gap: 30px;
  @media (max-width: 900px) {
    align-self: center;
    gap: 20px;
  }
`;

const DateTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const DateText = styled.div`
  font-family: "TheSeasons", serif;
  font-size: 50px;
  line-height: 50px;
  word-spacing: 15px;

  @media (max-width: 900px) {
    font-size: 35px;
    line-height: 35px;
  }
  @media (max-width: 480px) {
    /* Styles for mobile devices */
    font-size: 25px;
    line-height: 25px;
  }
  @media (max-width: 300px) {
    /* Styles for mobile devices */
    font-size: 20px;
    line-height: 20px;
  }
`;
const TorontoText = styled.div`
  font-family: "TheSeasons", serif;
  font-size: 30px;
  text-align: center;
  padding: 20px;

  @media (max-width: 900px) {
    font-size: 20px;
    padding: 10px;
  }
  @media (max-width: 480px) {
    /* Styles for mobile devices */
    font-size: 15px;
  }
  @media (max-width: 300px) {
    /* Styles for mobile devices */
    font-size: 15px;
  }
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 2px solid rgba(52, 17, 26, 0.1);
  border-radius: 8px;
  padding: 15px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #34111a;

  &:hover {
    background-color: rgba(52, 17, 26, 0.05);
  }

  svg {
    margin-right: 15px;
  }
`;

const StyledCalendar = styled(Calendar)`
  margin-right: 8px;
  width: 40px;
  height: 40px;
  stroke-width: 1.5;

  @media (max-width: 900px) {
    width: 28px;
    height: 28px;
  }
  @media (max-width: 480px) {
    width: 20px;
    height: 20px;
  }
  @media (max-width: 300px) {
    width: 16px;
    height: 16px;
  }
`;

const handleButtonClick = () => {
  atcb_action({
    listStyle: "dropdown-static",
    name: "Save The Date - Forum and Kunal's Wedding",
    startDate: "2025-08-31",
    endDate: "2025-08-31",
    location: "Toronto, ON, Canada",
    options: [
      "Apple",
      "Google",
      "Outlook.com",
      "Microsoft365",
      "Yahoo",
      "iCal",
    ],
    iCalFileName: "save_the_date",
    timeZone: "America/New_York",
    hideBackground: false,
    hideCheckmark: true,
    trigger: "click",
    inline: false,
  });
};

export const KunalAndForumText = () => {
  return (
    <KunalAndForumTextContainer>
      <KunalAndForumTextWrapper>
        <Text>Kunal &</Text>
        <Text>Forum</Text>
      </KunalAndForumTextWrapper>
      <DateTextContainer onClick={handleButtonClick}>
        {/* <DateText>Aug</DateText>
        <DateText>31</DateText>
        <DateText>2025</DateText> */}
        <StyledButton>
          <StyledCalendar />
          <DateText>31 Aug 2025</DateText>
        </StyledButton>
      </DateTextContainer>
      <TorontoText>Toronto, Canada</TorontoText>
    </KunalAndForumTextContainer>
  );
};
