import styled from "styled-components";
import "react-photo-album/rows.css";
import { HeroBanner } from "./components/HeroBanner/HeroBanner";
import { ProfileSection } from "./components/ProfileSection/ProfileSection";
import { OurStorySection } from "./components/OurSectionSection/OurStorySection";
import { Footer } from "./components/Footer/Footer";
const AppWrapper = styled.div``;

export default function App() {
  return (
    <AppWrapper>
      <HeroBanner />
      <ProfileSection />
      <OurStorySection />
      <Footer />
    </AppWrapper>
  );
}
