import styled from "styled-components";

const SaveTheDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  padding-top: 80px;
  justify-content: center;

  @media (max-width: 900px) {
    gap: 20px;
    padding-top: 70px;
  }
  @media (max-width: 480px) {
    gap: 10px;
    padding-top: 50px;
  }
`;

export const Text = styled.div`
  font-family: "TheSeasons", serif;
  font-size: 100px;
  line-height: 100px;

  @media (max-width: 900px) {
    font-size: 70px;
    line-height: 70px;
  }
  @media (max-width: 480px) {
    /* Styles for mobile devices */
    font-size: 50px;
    line-height: 50px;
  }
  @media (max-width: 300px) {
    /* Styles for mobile devices */
    font-size: 35px;
    line-height: 35px;
  }
`;

const TheText = styled.div`
  font-family: "BDScript", sans-serif;
  font-size: 225px;
  line-height: 100px;
  @media (max-width: 900px) {
    font-size: 170px;
    line-height: 70px;
  }
  @media (max-width: 480px) {
    /* Styles for mobile devices */
    font-size: 140px;
    line-height: 50px;
  }
  @media (max-width: 480px) {
    /* Styles for mobile devices */
    font-size: 80px;
    line-height: 45px;
  }
`;

export const SaveTheDateText = () => {
  return (
    <SaveTheDateContainer>
      <Text>Save</Text>
      <TheText>The</TheText>
      <Text>Date</Text>
    </SaveTheDateContainer>
  );
};
