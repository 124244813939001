import styled from "styled-components";

const StyledSectionWrapper = styled.div`
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor || "unset"};
  color: #34111a;
  h1 {
    color: ${({ titleColor }) => titleColor || "#34111a"};
  }
`;

const StyledSection = styled.div`
  max-width: 1500px;
  margin: 0 auto;
`;
const StyledSectionContent = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 20px 0 60px 0;
`;

const SectionTitle = styled.h1`
  font-family: "TheSeasons", serif;
  font-size: 50px;
  text-align: center;
`;

export const Section = ({
  children,
  backgroundColor,
  titleColor,
  sectionTitle,
}) => {
  return (
    <StyledSectionWrapper
      backgroundColor={backgroundColor}
      titleColor={titleColor}
    >
      <StyledSection>
        <StyledSectionContent>
          {sectionTitle ? <SectionTitle>{sectionTitle}</SectionTitle> : null}
          {children}
        </StyledSectionContent>
      </StyledSection>
    </StyledSectionWrapper>
  );
};
